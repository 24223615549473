import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import { IDataLayerParams } from '../../../../../../hooks/useDataLayer/typesUseDataLayer'

// Hooks
import useDataLayer from '../../../../../../hooks/useDataLayer/dataLayerBody'

// Components
import BreadCrumb from 'src/components/Breadcrumb'

import { Section, BgColumn, Button } from './style'

type HeroProps = {
  setIsModalOpen: Function;
  setDataLayer: (state: IDataLayerParams) => void;
}

const Hero = ({ setIsModalOpen, setDataLayer }: HeroProps) => {
  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Gestão patrimonial personalizada para você focar no que é inesquecível',
    element_action: 'click button',
    element_name: 'Se torne Win',
  }

  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()

  return (
    <Section className='py-3 py-lg-5'>
      <BgColumn />
      <div className='container'>
        <div className='row align-items-center justify-content-between m'>
          <div className='col-12 col-md-7 col-lg-6 col-xl-8 pb-md-0 order-2 order-md-1 pt-3 pt-md-5 mt-lg-5 pt-md-0 mt-md-0 mb-md-5'>
            <div>
              <BreadCrumb
                sectionName='Seu salário e sua margem valem mais no Consignado Inter!'
                type='Pra você'
                product='Relacionamento'
                text='Inter Wealth Management'
                link='/pra-voce/relacionamento/inter-win/'
              />
            </div>
            <div className='col-8 col-lg-4 pt-md-3 ml-n3 mt-4'>
              <Img fluid={data.win.fluid} alt='App Inter' />
            </div>
            <h1 className='fs-24 lh-32 fs-md-26 fs-lg-30 lh-lg-40 fs-xl-40 lh-xl-50 text-white mt-4 mt-lg-3'>
              Gestão patrimonial personalizada para você focar no que é inesquecível
            </h1>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-20 lh-lg-24 fw-400 text-white pb-2 mt-4 pb-md-0 pr-lg-2'>
              Unimos tecnologia ao entendimento daquilo que você mais valoriza para oferecer uma abordagem sob medida para o seu patrimônio.
            </p>
            <Button
              onClick={() => {
                setIsModalOpen(true)
                sendDatalayerEvent(dataLayer)
                setDataLayer(dataLayer)
              }}
            >
              Se torne Win
            </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
