import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
// import backgroundColumn from '../../assets/images/backgroundColumn.png'
// import backgroundColumnMd from '../../assets/images/backgroundColumnMd.png'
// import backgroundColumnLg from '../../assets/images/backgroundColumnLg.png'

// Font
import Obviously from '../../../../../../styles/fonts/obviously_wide_light.otf'

export const Section = styled.section`
  @font-face {
    font-family: 'Obviously';
    src: url(${Obviously}) format('opentype');
    font-display: swap;
  }

  position: relative;
  align-items: center;
  background-color: #323747;
  display: flex;
  min-height: 520px;
  width: 100%;
  padding-top: 0;

  @media ${device.tablet} {
    background-color: #323747;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/768-hero-win/image.webp');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;
    min-height: 544px;
    position: relative;
    padding-top: 0;
  }

  @media ${device.desktopLG} {
    min-height: 676px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/768-hero-win/image.webp');
  }

  @media ${device.desktopXL} {
    min-height: 100vh;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/768-hero-win/image.webp');
  }

  h1 {
    font-family: 'Obviously' !important;
    color: #FFFFFF;

    @media ${device.tablet} {
      font-family: 'Obviously';
    }

    @media ${device.desktopLG} {
      font-family: 'Obviously';
      width: 456px;
    }

    @media ${device.desktopXL} {
      font-family: 'Obviously';
      width: 648px;
      height: 208px;
    }
  }

  p {
    font-family: 'Sora';
  }
`
export const BgColumn = styled.div`
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/360-hero-win/image.webp');
  background-repeat: no-repeat;
  position: absolute;
  width: 137px;
  height: 239px;
  bottom: 296px;

  @media ${device.tablet} {
    width: 171px;
    height: 248px;
    bottom: 244px;
  }

  @media ${device.desktopLG} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/360-hero-win/image.webp');
    background-size: 520px;
    width: 209px;
    height: 383px;
    bottom: 247px;
  }

  @media ${device.desktopXL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/360-hero-win/image.webp');
    background-size: 770px;
    width: 315px;
    height: 538px;
    bottom: inherit;
    top: 35%;
    transform: translateY(-50%);
  }
`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  padding: 15px 16px;
  border: none;
  background: #FF7A00;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  margin-top: 24px;
  margin-bottom: 32px;

  &:hover {
    text-decoration: none !important;
    background: #ff8700;
    color: #fff;
    opacity: .9;
  }

  @media ${device.desktopLG} {
    max-width: 456px;
    margin-top: 32px;
  }
  @media ${device.desktopXL} {
    max-width: 460px;
  }
`
