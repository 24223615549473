import React from 'react'
import ImageWebp from 'src/components/ImageWebp'

import { Section } from './style'
import Dot from '../../assets/images/dot.png'

const EspecialistasTrabalhando = () => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row justify-content-md-end'>
          <div className='col-12 col-md-6'>
            <div className='img-estecialistas-trabalhando d-flex justify-content-center'>
              <ImageWebp
                arrayNumbers={[ 312, 312, 312, 312 ]}
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-mobile-win/image.webp'
                altDescription='Empresária sentada em uma poltrona na classe executiva de um avião checando o extrato de sua conta internacional no Super App.'
                className='d-md-none'
              />
            </div>
            <h2 className='fs-22 fs-lg-26 fs-xl-36 lh-31 lh-md-32 lh-xl-48'>
              Especialistas trabalhando para o que é mais importante: você.
            </h2>
            <div className='mt-4'>
              <ul className='list pl-0'>
                <li className='d-flex mb-4 align-items-start'>
                  <div className='d-flex mt-1'>
                    <img src={Dot} alt='dot' width='17' />
                  </div>
                  <p className='fs-14 fs-lg-16 fs-xl-18 lh-20 fw-400'>Conversa sobre suas metas de curto e longo prazo.</p>
                </li>

                <li className='d-flex mb-4 align-items-start'>
                  <div className='d-flex mt-1'>
                    <img src={Dot} alt='dot' width='17' />
                  </div>
                  <p className='fs-14 fs-lg-16 fs-xl-18 lh-20 fw-400'>Elaboração de um diagnóstico patrimonial feito exclusivamente para você.</p>
                </li>

                <li className='d-flex mb-4 align-items-start'>
                  <div className='d-flex mt-1'>
                    <img src={Dot} alt='dot' width='17' />
                  </div>
                  <p className='fs-14 fs-lg-16 fs-xl-18 lh-20 fw-400'>Execução de todo o planejamento após a sua aprovação.</p>
                </li>

                <li className='d-flex mb-4 align-items-start'>
                  <div className='d-flex mt-1'>
                    <img src={Dot} alt='dot' width='17' />
                  </div>
                  <p className='fs-14 fs-lg-16 fs-xl-18 lh-20 fw-400'>Monitoramento de oportunidades e respostas rápidas às suas necessidades de mudança.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default EspecialistasTrabalhando
