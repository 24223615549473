import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import { IDataLayerParams } from '../../../../../../hooks/useDataLayer/typesUseDataLayer'

// Hooks
import useDataLayer from '../../../../../../hooks/useDataLayer/dataLayerBody'

// Components
import { OrangeIcon } from 'src/components/UI/MarkdownIcon'

import { Section, Card } from './style'

const EntreEmContato = () => {
  const dataLayer: IDataLayerParams = {
    section: 'dobra_06',
    section_name: 'Para saber mais, entre em contato - Já sou cliente Win',
    element_action: 'click button',
    element_name: '(31) 3003-7107',
  }

  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()

  return (
    <Section className='py-5 d-flex align-items-xl-center' id='entre-em-contato'>

      <img className='OGraph d-md-none' src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra6-360-branco-win/image.webp' alt='' />
      <img className='OGraph d-none d-md-block d-lg-none' src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra6-768-branco/image.webp' alt='' />
      <img className='OGraph d-none d-xl-none d-lg-block' src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra6-1024-branco-win/image.webp' alt='' />
      <img className='OGraph d-none d-xl-block' src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra6-1440-branco-win/image.webp' alt='' />
      <img className='SGraph d-xl-none' src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra6-360-laranja-win/image.webp' alt='' />
      <img className='SGraph d-none d-xl-block' src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra6-1440-laranja-win/image.webp' alt='' />

      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-xl-5'>
            <div className='col-6 col-md-4 col-lg-4 col-xl-6 pr-xl-5 pt-md-5 ml-n3 mb-3'>
              <Img fluid={data.winDark.fluid} alt='Win' />
            </div>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-20 lh-lg-23 lh-xl-28 fw-400 subtitle'>Tenha uma experiência de alto  padrão unindo todas as nossas  soluções para a gestão do seu patrimônio, um banco digital completo e tudo que o Super App do Inter oferece.</p>
            <h3 className='fs-20 fs-lg-24 fs-xl-30 lh-28 lh-lg-35 lh-xl-48'>Para saber mais, entre em contato.</h3>
          </div>
          <div className='col-12 col-xl-5 pt-2 pt-md-4 pt-xl-0'>
            <div className='row'>
              <div className='col-12 col-md-6 col-xl-12'>
                <div>
                  <Card className='mb-4'>
                    <h4 className='fs-16 lh-25'>Já sou cliente Win</h4>
                    <div className='d-flex mb-3'>
                      <div className='mr-2'>
                        <OrangeIcon size='MD' color='#B75432' icon='whatsapp' className='whatsapp' />
                      </div>
                      <p className='fs-14 lh-17 text-left m-0'>
                        <span>Entre em contato pelo telefone e </span>
                        <span className='d-inline'>WhatsApp exclusivo para clientes Win </span>
                        <a
                          onClick={() => {
                            sendDatalayerEvent(dataLayer)
                          }}
                          href='https://wa.me/3130037107'
                          target='_blank'
                          rel='noreferrer'
                          className='fw-700'
                        ><span>(31) 3003-7107</span>
                        </a>
                      </p>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                      <div className='mr-2'>
                        <OrangeIcon size='MD' color='#B75432' icon='email' />
                      </div>
                      <p className='fs-14 lh-17 text-left m-0'>Mande um e-mail para o seu banker</p>
                    </div>
                    <div className='d-flex'>
                      <div className='mr-2'>
                        <OrangeIcon size='MD' color='#B75432' icon='video-camera' />
                      </div>
                      <p className='fs-14 lh-17 text-left m-0'>Agende uma videoconferência com seu banker pelo aplicativo</p>
                    </div>
                  </Card>
                </div>
              </div>
              <div className='col-12 col-md-6 col-xl-12'>
                <div>
                  <Card>
                    <h4 className='fs-16 lh-25'>Endereços comerciais</h4>
                    <div className='d-flex mb-3'>
                      <div className='mr-2'>
                        <OrangeIcon size='MD' color='#B75432' icon='location' />
                      </div>
                      <div>
                        <p className='fs-14 lh-17 fw-700 fw-md-600 mb-1 text-left mt-0'>Belo Horizonte</p>
                        <p className='fs-14 lh-17 lh-md-18 mb-0 text-left'>
                          <span className='d-md-block'>Av. Barbacena, 1219 – Santo Agostinho</span> Belo Horizonte | MG <span className='d-none d-md-inline'>|</span> CEP 30190-131
                        </p>
                      </div>
                    </div>
                    <div className='d-flex mb-3'>
                      <div className='mr-2'>
                        <OrangeIcon size='MD' color='#B75432' icon='location' />
                      </div>
                      <div>
                        <p className='fs-14 lh-17 fw-700 fw-md-600 mb-1 mt-0 text-left'>São Paulo</p>
                        <p className='fs-14 lh-17 lh-md-18 mb-0 text-left'>
                          <span className='d-md-block'>Av. Juscelino Kubitscheck, 1400 – 8º andar</span> São Paulo | SP <span className='d-none d-md-inline'>|</span><span className='d-block d-md-inline'> CEP 04543-000</span>
                        </p>
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <div className='mr-2'>
                        <OrangeIcon size='MD' color='#B75432' icon='email' />
                      </div>
                      <p className='fs-14 lh-17 fw-700 fw-md-600 m-0 text-left'>contato.win@bancointer.com.br</p>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default EntreEmContato
