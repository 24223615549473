import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

// Font
import Obviously from '../../../../../../styles/fonts/obviously_wide.otf'

export const Section = styled.section`
  @font-face {
    font-family: 'Obviously';
    src: url(${Obviously}) format('opentype');
    font-display: swap;
  }

  background-color: #323747;
  display: flex;
  min-height: 727px;
  width: 100%;
  padding-top: 0;
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-360-win/image.webp');
  background-repeat: no-repeat;
  background-size: contain;
  align-items: center;

  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-768-win/image.webp');
    min-height: 520px;
  }

  @media ${device.desktopLG} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-1024-win/image.webp');
    min-height: 600px;
  }

  @media ${device.desktopXL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-win/image.webp');
    min-height: 800px;
  }

  h2 {
    font-family: 'Obviously';
    font-style: normal;
    color: #E3E1D3;
    margin-top: 16px;

    @media ${device.desktopLG} {
      font-family: 'Obviously';
      margin-bottom: 16px;
    }

    @media ${device.desktopXL} {
      font-family: 'Obviously';
      margin-bottom: 56px;
    }
  }

  h3 {
    font-family: 'Obviously';
    color: #E3E1D3;
    margin-bottom: 8px;

    @media ${device.desktopLG} {
      font-family: 'Obviously';
    }

    @media ${device.desktopXL} {
      font-family: 'Obviously';
      font-size: 20px;
      line-height: 25px;
    }
  }

  p {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #E3E1D3;
    margin-bottom: 0px;

    @media ${device.tablet} {
      font-size: 16px;
      line-height: 20px;
      margin-top: 16px;
    }

    @media ${device.desktopLG} {
      font-size: 16px;
      line-height: 23px;
    }
    
    @media ${device.desktopXL} {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .cardContainer {
    width: 312px;
    height: 276px;
    border: 1px solid #DEDFE4;
    border-radius: 16px;
    background-color: #323747;
    padding: 24px;

    flex-direction: column;
    justify-content: space-between;
    display: flex;

    @media ${device.tablet} {
      width: 336px;
      height: 276px;
    }

    @media ${device.desktopLG} {
      width: 336px;
      height: 276px;
    }

    @media ${device.desktopXL} {
      width: 359px;
      height: 276px;
    }
  }
  .react-multi-carousel-dot-list .react-multi-carousel-dot--active button {
    opacity: 1;
    background-color: rgb(255, 122, 0);
    width: 16px;
    height: 4px;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  padding: 15px 16px;
  border: none;
  background: #FF7A00;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  margin-top: 24px;

  &:hover {
    text-decoration: none !important;
    background: #ff8700;
    color: #fff;
    opacity: .9;
  }

  @media ${device.desktopLG} {
    max-width: 376px;
    margin-top: 32px;
  }
  @media ${device.desktopXL} {
    max-width: 457px;
  }
`
