import React from 'react'
import { Section, ZGraph } from './style'

const MundoMuda = () => {
  return (
    <Section className='py-5'>
      <ZGraph />
      <div className='container'>
        <div className='col-12 col-md-7 col-xl-8'>
          <h2 className='fs-22 lh-31 lh-md-32 lh-lg-46'>
            O mundo muda com o tempo. Suas prioridades também.
          </h2>
          <p className='fs-14 fs-lg-16 fs-xl-18 lh-20 lh-lg-24 lh-xl-28'>
            Entendendo o que você busca, conseguimos propor o melhor caminho para a gestão do seu patrimônio. Utilizando tecnologia de ponta, encurtamos a distância até os seus objetivos.
          </p>
        </div>
      </div>
    </Section>
  )
}

export default MundoMuda
