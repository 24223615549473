import React from 'react'
import ImageWebp from 'src/components/ImageWebp'

import OrangeIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'

import { Section } from './style'

const CartaoWin = () => {

  return (
    <Section>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-6 col-xl-5'>
            <h2 className='fs-26 fs-xl-36 lh-35 lh-xl-48'>
              <span className='d-block'>Cartão</span> Mastercard Win
            </h2>
            <div className='mt-4'>
              <ul className='list pl-0'>
                <li className='d-flex align-items-center mb-4'>
                  <div>
                    <OrangeIcon size='LG' color='#E3E1D3' icon='no-taxes' />
                  </div>
                  <p className='fs-14 fs-lg-16 fs-xl-18 lh-20 lh-lg-28 mb-0'>Sem anuidade</p>
                </li>

                <li className='d-flex align-items-center mb-4'>
                  <div>
                    <OrangeIcon size='MD' color='#E3E1D3' icon='inter-loop' />
                  </div>
                  <p className='fs-14 fs-lg-16 fs-xl-18 lh-20 lh-lg-28 mb-0'>Muito mais pontos no Loop, nosso programa de benefícios</p>
                </li>

                <li className='d-flex align-items-center mb-4'>
                  <div>
                    <OrangeIcon size='MD' color='#E3E1D3' icon='couch' />
                  </div>
                  <p className='fs-14 fs-lg-16 fs-xl-18 lh-20 lh-lg-28 mb-0'>Sala VIP nos principais aeroportos do mundo</p>
                </li>

                <li className='d-flex align-items-center mb-4'>
                  <div>
                    <OrangeIcon size='LD' color='#E3E1D3' icon='concierge' />
                  </div>
                  <p className='fs-14 fs-lg-16 fs-xl-18 lh-20 lh-lg-28 mb-0'>Concierge Mastercard</p>
                </li>

                <li className='d-flex align-items-center'>
                  <div>
                    <OrangeIcon size='LG' color='#E3E1D3' icon='security' />
                  </div>
                  <p className='fs-14 fs-lg-16 fs-xl-18 lh-20 lh-lg-28 mb-0'>Seguros viagem, compra protegida e mais</p>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-6 px-0'>
            <div className='pb-4 pb-md-0 mx-auto ml-md-3'>
              <ImageWebp
                arrayNumbers={[ 378, 344, 464, 560 ]}
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-1440-1024-win/image.webp'
                altDescription='Investidor'
                className='mb-4 mb-md-0'
              />
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CartaoWin
