import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'

// Hooks
import useDataLayer from '../../../../../../hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from '../../../../../../hooks/useDataLayer/typesUseDataLayer'

// Components
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import pageFuturo from './pageFuturo.json'

import { Section, Button } from './style'

type SejaFuturoProps = {
  setIsModalOpen: Function;
  setDataLayer: (state: IDataLayerParams) => void;
}

const SejaFuturo = ({ setIsModalOpen, setDataLayer }: SejaFuturoProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_04',
    section_name: 'Seja qual for o futuro que você quer construir, nós temos a expertise para levá-lo até lá.',
    element_action: 'click button',
    element_name: 'Se torne Win',
  }

  return (
    <Section className='pb-4 py-5 py-md-5 position-relative overflow-lg-hidden'>
      <div className='container'>
        <div className='row d-flex justify-content-center justify-content-lg-start'>
          <div className='col-12 col-md-6'>
            <div className='col-6 col-md-6 col-lg-4 col-xl-6 pr-xl-5 pt-md-5 ml-n3'>
              <Img fluid={data.win.fluid} alt='Win' />
            </div>
            <h2 className='fs-22 fs-lg-26 fs-xl-36 lh-32 lh-lg-35 lh-xl-48'>
              Seja qual for o futuro que você quer construir, nós temos a expertise para levá-lo até lá.
            </h2>
            <Button
              className='d-none d-md-block'
              onClick={() => {
                setIsModalOpen(true)
                sendDatalayerEvent(dataLayer)
                setDataLayer(dataLayer)
              }}
            >
              Se torne Win
            </Button>
          </div>
          <div className='col-12 col-md-6 pl-md-0'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1, partialVisibilityGutter: 100 }}
              xl={{ items: 1, partialVisibilityGutter: 150 }}
            >
              {
                pageFuturo.map((item) => (
                  <div key={item.title} className='cardContainer'>
                    <OrangeIcon size='MD' icon={item.icon} color='#E3E1D3' className='iconContainer' />
                    <div>
                      <h3 className='fs-16 fs-lg-20 lh-22 lh-lg-25 lh-xl-28'>{item.title}</h3>
                      <p className=''>{item.description}</p>
                    </div>
                  </div>
                ))
              }
            </DefaultCarousel>
          </div>
          <Button
            className='d-md-none'
            onClick={() => {
              setIsModalOpen(true)
            }}
          >
            Se torne Win
          </Button>
        </div>
      </div>
    </Section>
  )
}

export default SejaFuturo
