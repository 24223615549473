import React, { useRef, useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import Layout from './../../../../layouts/BaseLayout'

// Hooks
import useDomReady from '../../../../hooks/window/useDomReady'
import useScroll from '../../../../hooks/window/useScroll'
import { IDataLayerParams } from '../../../../hooks/useDataLayer/typesUseDataLayer'

// Components
import WinForm from '../../../../components/UI/Forms/WinForm/index'
import { Modal } from 'src/components/Modal'

// Sections
import BannerHeader from './sections/hero/_index'
import MundoMuda from './sections/mundo-muda/_index'
import EspecialistasTrabalhando from './sections/especialistas-trabalhando/_index'
import SejaFuturo from './sections/seja-futuro/_index'
import CartaoWin from './sections/cartao-win/_index'
import Faq from './sections/faq/_index'
import EntreEmContato from './sections/entre-em-contato/_index'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

const IndexPageComponent: React.FC = (): React.ReactElement => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const scrollPositionY = useScroll(100)
  const isScrolled = scrollPositionY > 200

  const contatoFormRef = useRef<HTMLDivElement>()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const domReady = useDomReady()

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Gestão patrimonial personalizada para você focar no que é inesquecível',
    element_action: 'click button',
    element_name: 'Se torne Win',
  })

  useEffect(() => {
    if (contatoFormRef.current && queryParams.fs) {
      contatoFormRef.current.scrollIntoView({ block: 'start' })
    }
  }, [])

  const modalForm = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <WinForm
        closeModal={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        dataLayer={dataLayer}
      />
    </Modal>
  )

  return (
    <Wrapper scrolled={isScrolled}>
      {modalForm}
      <Layout pageContext={pageContext}>
        <BannerHeader setIsModalOpen={setIsModalOpen} setDataLayer={setDataLayer} />
        <MundoMuda />
        <EspecialistasTrabalhando />
        <SejaFuturo setIsModalOpen={setIsModalOpen} setDataLayer={setDataLayer} />
        <CartaoWin />
        <EntreEmContato />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default IndexPageComponent
