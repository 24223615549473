import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

// Font
import Obviously from '../../../../../../styles/fonts/obviously_wide.otf'

export const Section = styled.section`
  @font-face {
    font-family: 'Obviously';
    src: url(${Obviously}) format('opentype');
    font-display: swap;
  }

  align-items: center;
  background-color: #B75432;
  display: flex;
  min-height: 360px;
  width: 100%;
  padding-top: 0;

  @media ${device.tablet} {
    min-height: 550px;
  }

  @media ${device.desktopLG} {
    min-height: 600px;
  }

  @media ${device.desktopXL} {
    min-height: 853px;
  }

  h2 {
    font-family: 'Obviously';
    font-style: normal;
    color: #E3E1D3;
    margin-bottom: 30px;
    margin-top: 40px;

    @media ${device.tablet} {
      color: #E3E1D3;
      margin-bottom: 30px;
    }

    @media ${device.desktopLG} {
      font-family: 'Obviously';
      margin-bottom: 34px;
      width: 616px;
    }

    @media ${device.desktopXL} {
      font-family: 'Obviously';
      margin-bottom: 24px;
      width: 640px;
    }
  }

  p {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    color: #E3E1D3;
    margin-left: 28px;
    align-self: center;

    @media ${device.tablet} {
      color: #E3E1D3;
    }
    
    @media ${device.desktopXL} {
      font-weight: 400;
      width: 748px;
    }
  }
`
