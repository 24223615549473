import React from 'react'

// Components
import FAQ from 'src/components/Faq'

import pageContext from '../../pageContext.json'
import { Section } from './style'

const FaqComponent = () => {
  return (
    <Section className='py-5' id='faq'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h3 className='fs-22 fs-md-28 fs-lg-32 lh-31 lh-md-48'>Perguntas frequentes</h3>
            <FAQ
              id='inter-black'
              className='summary-content px-0 text-white'
              columns={{ lg: 1, xl: 1 }}
              answerData={pageContext.structuredData.faq}
              questionColor='text-white'
              answersColor='text-white'
              borderColor='#6A6C72'
              searchBg='#323747'
              placeholderColor='#f6f6fa'
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default FaqComponent
