import styled, { css } from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'

type WrapProps = {
  scrolled: boolean;
}

export const Wrapper = styled.div<WrapProps>`
  .container-footer {
    background-color: #323747 !important;
    
    svg {
      max-width: 100%;
      fill: ${white};
      margin-left: -1px;
    }

    .selo-nasdaq {
      span {
        color: #FFFFFF;
      }
    }

    .bureau-veritas {
      svg {
        path {
          fill: ${white};
        }
      }
    }

    .title-font {
      color: ${white};
    }
    
    .for-you, .need-help, .contact, .sub-footer {
      ul {
        li {
          a {
            color: ${white};
          }
          a {
            &:hover {
              color: ${orange.extra};
            }
          }
        }
      }
    }

    .list-phones li {
      color: ${white};

      a {
        &:hover {
          color: ${orange.extra};
        }
      }
    }

    address {
      color: ${white};
    }

    .address {
      color: ${white};
        &:hover {
          color: ${orange.extra};
        }
      }
    }
  }
  
  header {
    section {
      background: #323747 !important;

      @media ${device.desktopLG} {
        background: transparent !important;
      }

      @media ${device.desktopLG} {
        ${(props: WrapProps) => props.scrolled && css` 
          background: #323747 !important;
        `}
      }
    }
  }
`
